body{

    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    background-size: 100%;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;

    @media(max-width: 767px){
        background-image: url('/assets/images/error/page-not-found-768.jpg');
    }
    @media(min-width: 768px){
        background-image: url('/assets/images/error/page-not-found-992.jpg');
    }
    @media(min-width: 992px){
        background-image: url('/assets/images/error/page-not-found-1200.jpg');
    }
    @media(min-width: 1200px){
        background-image: url('/assets/images/error/page-not-found-1800.jpg');
    }
    @media(min-width: 1800px){
        background-image: url('/assets/images/error/page-not-found.jpg');
    }
}

.error-404-container{
    color: #fff;

    @media(max-width: 767px){
        margin-top: 10px;
    }
    @media(min-width: 768px){
        margin-top: 20px;
    }
    @media(min-width: 992px){
        margin-top: 30px;
    }

    h1{
        font-weight: bold;
    }

    a, a:hover {
        color: #fff;
        text-decoration: underline;
    }
}
